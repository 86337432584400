import { emptyCulture, getColorByName, getisArableByName } from 'utils/culturesColors';
import { formatNumberWithSpaces } from 'utils';

export const sortList = (culturesByYear, year) => {
  const sortedList = [...culturesByYear];

  return sortedList
    ?.find((e) => e.year === year)
    .cultures?.sort((a, b) => a?.name?.localeCompare(b.name));
};

export const parcelHoverCultureOpacity = (cultureName, parcelHoverCultureName) => {
  if (parcelHoverCultureName) {
    if (Array.isArray(parcelHoverCultureName)) {
      return parcelHoverCultureName.some((culture) => culture?.name === cultureName) ? '1' : '0.3';
    }

    return cultureName === parcelHoverCultureName ? '1' : '0.3';
  }
  return 'unset';
};

export const cultureHover = (cultureName, parentCallback) => {
  parentCallback(cultureName);
};

export const loadResultMapTabData = (
  data,
  culturesColors,
  minYear,
  maxYear,
  fromParcels = false
) => {
  let totalSurfaceIncrement = 0;
  let allCulturesByYear = [];

  const addCultureOrDividedCultures = (
    culture,
    parcel,
    isParcelDivision = false,
    cultureYear = null
  ) => {
    if (isParcelDivision && culture.length > 0) {
      culture.forEach((cultureItem) => {
        addCultureOrDividedCultures(cultureItem, parcel, false, culture?.year);
      });
    } else {
      if (culture) {
        cultureYear = cultureYear ?? culture?.year;
        const currentYear = allCulturesByYear?.find((e) => e?.year === cultureYear);
        if (!currentYear) {
          allCulturesByYear?.push({
            year: cultureYear,
            cultures: [],
            totalArableSurface: 0,
          });
          addNewCultureByYear(allCulturesByYear, culture, parcel, cultureYear);
        } else {
          const cultureInList = currentYear.cultures?.find(
            (currentYearCulture) => currentYearCulture.name === culture?.name
          );
          if (!cultureInList) {
            addNewCultureByYear(allCulturesByYear, culture, parcel, cultureYear);
          } else {
            allCulturesByYear
              .find((culturesByYear) => culturesByYear.year === cultureYear)
              .cultures.forEach((c) => {
                if (c.id === culture.id) {
                  c.surface += culture?.surface ?? parcel.surface;
                }
              });
          }
        }
      }
    }
  };

  function addNewCultureByYear(allCulturesByYear, culture, parcel, cultureYear) {
    allCulturesByYear
      ?.find((e) => e.year === cultureYear)
      .cultures?.push({
        name: culture?.name,
        color: getColorByName(culture?.name, culturesColors),
        year: cultureYear,
        surface: culture?.surface ?? parcel.surface,
        isArable: getisArableByName(culture?.name, culturesColors),
        id: culture.id,
      });
  }

  if (fromParcels) {
    data.map((parcel) => {
      if (parcel) {
        if (parcel.cultureN?.year) {
          addCultureOrDividedCultures(parcel.cultureN, parcel);
        } else {
          addCultureOrDividedCultures({ ...emptyCulture, year: maxYear }, parcel);
        }
        if (parcel.cultureN1?.year) {
          addCultureOrDividedCultures(parcel.cultureN1, parcel);
        } else {
          addCultureOrDividedCultures({ ...emptyCulture, year: minYear }, parcel);
        }
        totalSurfaceIncrement = totalSurfaceIncrement + Number(parcel.surface);
      }
    });
  } else {
    data.map((parcel) => {
      if (parcel.cultureN?.year && !parcel?.parent) {
        addCultureOrDividedCultures(parcel.cultureN, parcel);
        parcel.cultures.map((cultureArray, i) => {
          let newYear = parcel.cultureN.year + (i + 1);
          cultureArray.year = newYear;
          addCultureOrDividedCultures(cultureArray, parcel, true);
        });
      }
      totalSurfaceIncrement = totalSurfaceIncrement + Number(parcel.surface);
    });
  }

  allCulturesByYear.forEach((year) => {
    let totalArableSurfaceForYear = 0;
    year.cultures.forEach((culture) => {
      if (culture.isArable) {
        totalArableSurfaceForYear = totalArableSurfaceForYear + culture.surface;
      }
    });
    year.totalArableSurface = totalArableSurfaceForYear;
  });

  return { allCulturesByYear, totalSurfaceIncrement };
};

export const getCultureGrossMargin = (culture, previousCropMargin, yearIndex, suggestions, surface = 0) => {
  if (yearIndex === null) {
    return;
  }

  let grossMargin = 0;

  if (yearIndex < 0) {
    grossMargin = previousCropMargin?.find(
      (margin) => margin?.culture?.id === culture.id
    )?.grossMargin;
  } else if (suggestions && yearIndex in suggestions && yearIndex >= 0) {
    grossMargin = suggestions[yearIndex].cropsGrossMargin?.find(
      (margin) => margin?.culture?.id === culture?.id
    )?.grossMargin;
  }

  if (grossMargin === undefined) {
    return;
  }

  if(surface !== 0) {
  return formatNumberWithSpaces(Math.round(grossMargin/surface));
  }

  return formatNumberWithSpaces(Math.round(grossMargin));
};