import { renderToString } from 'react-dom/server';

import { jsPDF } from 'jspdf';
import { addZero } from 'utils/index';

import logoAssolia from 'assets/images/logo-min.png';
import Rubik from 'assets/fonts/Rubik-Regular.ttf';
import RubikBold from 'assets/fonts/Rubik-Bold.ttf';

import PdfComponent from './PdfComponent';

const PdfDocument = async (
  results,
  proposal,
  exploitation,
  user,
  sector,
  graphMarge,
  keyNumber,
  keyNumberNitrogen,
  graphPerYear,
  fullCultures,
  cooperative,
  pac,
  pacHelps,
  jobId,
  currentYear,
  obligationRestrictions,
  isCoverProposalASelectedConstraint,
  isNitrogenASelectedConstraint,
  isExtraBiomassASelectedConstraint,
  dataCropGrossMargin,
  previousCropMargin,
  culturesColors
) => {
  //*create new PDF
  let pdf = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'landscape',
    format: 'a4',
    fontSize: 8,
    unit: 'px',
    hotfixes: ['px_scaling'],
    compress: true,
  });

  //*footer margin
  const footerMarginBottom = 5;
  const footerMarginSide = 30;
  const paginationMarginRight = 20;
  //* set max width and height for footer images
  const footerImgMaxWidth = 165;
  const footerImgMaxheight = 60;
  const totalFooterMargin = footerMarginBottom + footerImgMaxheight + 5;

  pdf.addFont(Rubik, 'Rubik', 'normal');
  pdf.addFont(RubikBold, 'Rubik', 'bold');
  pdf.setFont('Rubik');

  let date = new Date();
  // if one digit , force two digits with addZero function
  let dd = addZero(date.getDate());
  let MM = addZero(date.getMonth() + 1); //January is 0!
  let yyyy = date.getFullYear();
  let HH = addZero(date.getHours());
  let mm = addZero(date.getMinutes());
  let ss = addZero(date.getSeconds());

  let dformat = yyyy + MM + dd + HH + mm + ss;

  const data = {
    exploitation: exploitation,
    cooperative: cooperative,
    user: user,
    sector: sector,
    date: date,
    jobId: jobId,
    proposal: proposal,
    currentYear: currentYear,
    graphMarge: graphMarge,
    keyNumber: keyNumber,
    keyNumberNitrogen: keyNumberNitrogen,
    pac: pac,
    pacHelps: pacHelps,
    constraints: results?.constraints,
    obligationRestrictions: obligationRestrictions,
    cultures: fullCultures,
    graphPerYear: graphPerYear,
    isCoverProposalASelectedConstraint: isCoverProposalASelectedConstraint,
    isNitrogenASelectedConstraint: isNitrogenASelectedConstraint,
    isExtraBiomassASelectedConstraint: isExtraBiomassASelectedConstraint,
    dataCropGrossMargin: dataCropGrossMargin,
    previousCropMargin: previousCropMargin,
    culturesColors: culturesColors,
  };
  let html = renderToString(
    <PdfComponent data={data} results={results} totalFooterMargin={totalFooterMargin} />
  );

  await pdf.html(html, {
    autoPaging: 'text',
  });
  var pageCount = pdf.internal.getNumberOfPages();
  pdf.deletePage(pageCount);

  await generateFooter(
    pdf,
    pageCount,
    cooperative.logo,
    footerMarginBottom,
    footerMarginSide,
    paginationMarginRight,
    footerImgMaxWidth,
    footerImgMaxheight
  );

  return { pdf, dformat };
};

async function generateFooter(
  pdf,
  pageCount,
  cooperativeLogo,
  footerMarginBottom,
  footerMarginSide,
  paginationMarginRight,
  footerImgMaxWidth,
  footerImgMaxheight
) {
  //* create images footer
  const logoAssoliaInFooter = new Image();
  const logoCooperativeInFooter = new Image();
  logoAssoliaInFooter.src = logoAssolia;
  if (cooperativeLogo) {
    logoCooperativeInFooter.src = cooperativeLogo + '?not-from-cache-please';
  }

  //* setFontSize for upcoming text
  pdf.setFontSize(12);

  let logoAssoliaSidesLenght = await getScaledImageSideLength(
    logoAssoliaInFooter,
    footerImgMaxWidth,
    footerImgMaxheight
  );

  let logoCooperativeSidesLenght;
  if (cooperativeLogo) {
    logoCooperativeSidesLenght = await getScaledImageSideLength(
      logoCooperativeInFooter,
      footerImgMaxWidth,
      footerImgMaxheight
    );
  }

  //* add img and pagination to each page
  for (var i = 1; i <= pageCount - 1; i++) {
    pdf.setPage(i);
    pdf.text(
      String(i) + ' / ' + String(pageCount - 1),
      pdf.internal.pageSize.width - paginationMarginRight,
      pdf.internal.pageSize.height - 30,
      null,
      null,
      'right'
    );
    pdf.addImage(
      logoAssoliaInFooter,
      'png/jpeg',
      pdf.internal.pageSize.width -
        logoAssoliaSidesLenght.width -
        footerMarginSide -
        (paginationMarginRight + 5),
      pdf.internal.pageSize.height - logoAssoliaSidesLenght.height - footerMarginBottom,
      logoAssoliaSidesLenght.width,
      logoAssoliaSidesLenght.height
    );
    if (cooperativeLogo && logoCooperativeSidesLenght && i > 1) {
      pdf.addImage(
        logoCooperativeInFooter,
        'png/jpeg',
        footerMarginSide,
        pdf.internal.pageSize.height - logoCooperativeSidesLenght.height - footerMarginBottom,
        logoCooperativeSidesLenght.width,
        logoCooperativeSidesLenght.height
      );
    }
  }
}

async function getScaledImageSideLength(image, footerImgMaxWidth, footerImgMaxheight) {
  let realImageWidth;
  let realImageHeight;
  try {
    realImageWidth = await jsPDF.API.getImageProperties(image).width;
    realImageHeight = await jsPDF.API.getImageProperties(image).height;
  } catch (error) {
    return false;
  }

  //* calculate ratio
  const aspectRatioImage = realImageWidth / realImageHeight;

  let finalImageWidth = 0;
  let finalImageHeight = 0;

  let nearestSideToLenghtLimit = '';
  if (
    (footerImgMaxWidth - realImageWidth) / aspectRatioImage <=
    (footerImgMaxheight - realImageHeight) / (1 / aspectRatioImage)
  ) {
    nearestSideToLenghtLimit = 'width';
  } else {
    nearestSideToLenghtLimit = 'height';
  }

  if (
    (nearestSideToLenghtLimit === 'width' && footerImgMaxWidth - realImageWidth >= 0) ||
    (nearestSideToLenghtLimit === 'height' && footerImgMaxheight - realImageHeight >= 0)
  ) {
    finalImageWidth = realImageWidth;
    finalImageHeight = realImageHeight;
  } else if (nearestSideToLenghtLimit === 'width' && footerImgMaxWidth - realImageWidth < 0) {
    finalImageWidth = footerImgMaxWidth;
    finalImageHeight = footerImgMaxWidth / aspectRatioImage;
  } else {
    finalImageHeight = footerImgMaxheight;
    finalImageWidth = footerImgMaxheight * aspectRatioImage;
  }

  return { width: finalImageWidth, height: finalImageHeight };
}

export default PdfDocument;
