import { useContext } from 'react';
import './graphPerYear.style.scss';
import '../pdfComponent.style.scss';
import { PdfContext } from 'utils/context';
import { lengthInInterval, truncateTab } from '../Generics/utilsFunctions';
import { v1 } from 'uuid';
import CropGrossMarginTable from '../Tables/CropGrossMarginTable';

const GraphPerYearPage = () => {
  const { graphPerYear, proposal, currentYear } = useContext(PdfContext);
  const numberOfGraphPerPage = 1;
  let toDuplicate = true;
  let repetition = 1;
  let graphPerYearPages = [];
  const graphPerYearCharts = graphPerYear
    .find((prop) => prop.proposal === proposal)
    .charts;
  while (toDuplicate) {
    // On découpe la list des graphs en 2 graph par page pour que ce soit lisible
    let truncatedResult = truncateTab(graphPerYearCharts, numberOfGraphPerPage, repetition);
    let indexGraph = (repetition - 1) * numberOfGraphPerPage
    graphPerYearPages.push(
      buildGraphPerYear(
        truncatedResult,
        repetition > 1 ? '(Suite)' : '',
        currentYear,
        indexGraph
      )
    );
    lengthInInterval(graphPerYearCharts, repetition, numberOfGraphPerPage)
      ? repetition++
      : (toDuplicate = false);
  }

  return graphPerYearPages;
};

const buildGraphPerYear = (
  graphsToRender,
  message,
  currentYear,
  indexGraph
) => {
  return (
    <article className="pdf-page ">
      <div className="tableau_center">
        <h6 className="title_h6">{indexGraph === 0 ? 'Production année courante (ha)' : 'Production (ha)' } {indexGraph > 1 ? message : ''}</h6>
        <div className="multiple-graph-bloc">
          {graphsToRender?.map((img, idx) => (
            <div>
              <h6 className="title_campagne">
                Campagne {idx + currentYear + indexGraph - 1} -{' '}
                {idx + (currentYear + 1) + indexGraph - 1}
              </h6>
              <div key={v1()} className="graph">
                <div className='graphImgContainer'>
                  <img className="graph-production" src={img} alt="Graphique de production annuelle" />
                </div>
                <div className="pieChart_table">
                  <CropGrossMarginTable
                    yearIndex={idx + indexGraph - 1}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};

export default GraphPerYearPage;
