import { useContext } from "react";
import { PdfContext } from "utils/context";
import { getColorByName } from "utils/culturesColors";
import { getCultureGrossMargin, loadResultMapTabData, sortList } from "utils/Map/MapTabUtils";
import './table.style.scss';
import TotalMapTab from "components/generics/Map/TotalMapTab";


type cropsGrossMarginType = {
    dataGrossMargin: any;
    yearIndex: number;
}
type culture = {
    id: number;
    name: String;
    color: string;
    irrigationWaterNeeded: number;
    isArable: boolean | null;
    year: number;
    surface: number;
};


const CropGrossMarginTable = ({ yearIndex }: cropsGrossMarginType) => {
    const { suggest, culturesColors, currentYear, previousCropMargin, dataCropGrossMargin } = useContext(PdfContext);
    let headers = ['', 'Culture', 'Marge']
    let { allCulturesByYear, totalSurfaceIncrement } = loadResultMapTabData(
        dataCropGrossMargin,
        culturesColors,
        null,
        null,
        false
    );

    return (
        <div className="cultures-map-tab-container table">

            <div
                className={
                    'table-container sticky-table table-production'
                }
            >
                {!dataCropGrossMargin && (
                    <div
                        style={{
                            fontSize: 'bold',
                            fontFamily: 'Rubik',
                            padding: '20px',
                            color: '#707070',
                        }}
                    >
                        Pas de cultures pour cette année
                    </div>
                )}
                {dataCropGrossMargin && (
                    <table
                        className={
                            'cultures-map-tab-table'
                        }
                    >
                        <thead
                            style={{ fontSize: 'bold', fontFamily: 'Rubik' }}
                            className={
                                'table_head sticky-table'
                            }
                        >
                            <tr>
                                {headers.map((header) => (
                                    <th
                                        key={header}
                                        scope="col"
                                        className={header === 'Marge (€/ha)' ? 'last_column_name' : 'column_name'}
                                        colSpan={header === 'Marge' || header === 'Surface' ? 2 : 1}
                                    >
                                        <span>{header}</span>
                                    </th>
                                ))}
                            </tr>
                            {headers.includes('Surface') && (
                                <tr className="column_subName">
                                    <th colSpan={2}></th>
                                    <th colSpan={1}>(ha)</th>
                                    <th colSpan={1}>(%)</th>
                                    {headers.includes('Marge (€/ha)') && <th colSpan={2}></th>}
                                </tr>
                            )}
                            {headers.includes('Marge') && (
                                <tr className="column_subName">
                                    <th colSpan={2}></th>
                                    <th colSpan={1}>(€)</th>
                                    <th colSpan={1}>(€ / ha)</th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                            {sortList(allCulturesByYear, +currentYear + yearIndex + 1)
                                ?.filter((culture: culture) => culture.id !== undefined)
                                .map((culture: culture, i: number) => (

                                    <tr
                                        key={i}
                                        className="tr"
                                    >
                                        <td key={getColorByName(culture.name, culturesColors)}>
                                            <div
                                                className="culture-color"
                                                style={{ background: '#' + getColorByName(culture.name, culturesColors) }}
                                            ></div>
                                        </td>
                                        <>
                                            <td className="culture-line-clamp" key={culture.name as string}>
                                                {culture.name}
                                            </td>
                                            <td key={'marge_total'}>{getCultureGrossMargin(culture, previousCropMargin, yearIndex, suggest.years)}</td>
                                            <td key={'marge_ha'}>
                                                {getCultureGrossMargin(culture, previousCropMargin, yearIndex, suggest.years, culture.surface)}
                                            </td>
                                        </>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
                <TotalMapTab totalSurface={totalSurfaceIncrement} fromProduction={true} />
            </div>
        </div>
    );
}

export default CropGrossMarginTable
