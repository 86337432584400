import React, { useState, useEffect, useContext } from 'react';
import { v1 } from 'uuid';
import PropTypes, { any } from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MapConstraints from 'components/generics/Map/MapConstraints';
import exploitationProp from 'components/propTypes/exploitation';
import SimulationSteps from 'components/pages/Exploitation/Simulation/SimulationSteps';
import SimulationCard from 'components/pages/Exploitation/Simulation/SimulationCard';
import Checkbox from 'components/generics/Checkbox';
import Button from 'components/generics/Button';
import Input from 'components/generics/Input';
import {
  getCulturesByExploitationId,
  getConstraints,
  getObjectives,
  getParcelsByExploitation,
} from 'services/API/Exploitation';
import { lastSimulate } from 'services/API/Simulation';
import { simulationSteps } from 'utils/const';
import { sendEvent } from 'utils/Event';
import { ExploitationContext, NavContext } from 'utils/context';
import './simulation.styles.scss';
import '../../../assets/styles/button.style.scss';
import MinMaxCultures from './MinMaxCultures';
import { SimulationContext } from './SimulationContext';
import { Fragment } from 'react';
import { displayErrorMessage, emptyFieldToZero } from 'utils/tools_functions';
import { getTotalSurfaceParcelGroup } from 'utils/tools_functions';

import {
  inputRequired,
  inputIntegerValidator,
  inputYearNumberSimulation,
} from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';
import InputLabelOld from 'components/generics/InputLabel';
import { FormModal } from 'components/generics/Modals/FormModal';

import { Button as ButtonMui } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { MultiSelectTheme } from '../../../assets/styles/themes/multi_select_mui';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { Select as SelectMui } from '@mui/material';
import CheckboxMui from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import ExploitationHeader from './header/exploitation.header';

const SimulationExploitations = ({ exploitationSelected = {}, setExploitation = any }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { selectedParcels, setSelectedParcels } = React.useContext(SimulationContext);
  const { cultures, setCultures } = React.useContext(SimulationContext);
  const { obligationAndRestriction, setObligationAndRestriction } =
    React.useContext(SimulationContext);
  const { selectedCultures, setSelectedCultures } = React.useContext(SimulationContext);
  const { constraints, setConstraints } = React.useContext(SimulationContext);
  const { selectedConstraints, setSelectedConstraints } = React.useContext(SimulationContext);
  const { objectives, setObjectives } = React.useContext(SimulationContext);
  const { selectedObjectives, setSelectedObjectives } = React.useContext(SimulationContext);
  const [errorObligation, setErrorObligation] = useState('');
  const { nbYears, setNbYears } = React.useContext(SimulationContext);
  const [mins, setMins] = useState([]);
  const [maxs, setMaxs] = useState([]);
  const [minsPct, setMinsPct] = useState([]);
  const [maxsPct, setMaxsPct] = useState([]);
  const { limit, setLimit } = React.useContext(SimulationContext);
  const [exploit, setExploit] = useState(exploitationSelected);
  const { exploitationContext, setExploitationContext } = React.useContext(ExploitationContext);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    yearNumber: {
      message: '',
      validator: [inputRequired, inputIntegerValidator, inputYearNumberSimulation],
    },
  });
  const { isNavBarOpen, setIsNavBarOpen } = useContext(NavContext);
  const [mapTab, setMap] = useState(false);
  const [editData, setEditData] = useState(null);
  const [modal, setModal] = useState(false);
  const [simulationModalCulture, setSimulationModalCulture] = useState(null);
  const [interdictionList, setInterdictionList] = useState([]);
  const [obligationList, setObligationList] = useState([]);
  const [coordsExist, setCoordsExist] = useState(false);
  const [hasObligationOrRestriction, setHasObligationOrRestriction] = useState(false);
  const currentYear = +localStorage.getItem('currentYear');
  const [mapYear, setMapYear] = useState(currentYear + 1);
  const [parcelRestrictionValue, setparcelRestrictionValue] = useState(null);
  const [inputCheckedInterdiction, setInputCheckedInterdiction] = useState(false);
  const [inputCheckedObligation, setInputCheckedObligation] = useState(false);
  const [inputCheckedNeutre, setInputCheckedNeutre] = useState(false);
  const [toggleCoverProposal, setToggleCoverProposal] = useState(false);
  const [toggleExtraBiomass, setToggleExtraBiomass] = useState(false);
  const [toggleNitrogen, setToggleNitrogen] = useState(false);
  const [showExploitationHeader, setShowExploitationHeader] = useState(false);

  const validParcelSettings = selectedParcels?.length > 0;
  const validCultureSettings = selectedCultures?.filter((culture) => culture?.selected)?.length > 1;

  const validateStepOrSubmitSimu = () => {
    if (!validCultureSettings) {
      toast.error('Veuillez sélectionner au moins deux cultures');
      return false;
    }
    if (!validParcelSettings) {
      toast.error('Veuillez sélectionner au moins une parcelle');
      return false;
    }

    return true;
  };

  let updatedConstraints = [...selectedConstraints];
  const existingNitrogenKey = updatedConstraints.find((constraint) => 'nitrogen' in constraint);
  const existingCoverProposalKey = updatedConstraints.find(
    (constraint) => 'coverProposal' in constraint
  );

  const showMap = (bool) => {
    setMap(bool);
    setSimulationModalCulture(null);
  };

  useEffect(() => {
    if (!nbYears) {
      setNbYears(nbYears);
    }
  }, [nbYears]);

  useEffect(() => {
    if (!toggleNitrogen && !existingNitrogenKey) {
      updatedConstraints.push({
        nitrogen: false,
      });
    }
    if (!toggleCoverProposal && !existingCoverProposalKey) {
      updatedConstraints.push({
        coverProposal: false,
      });
    }
    setSelectedConstraints(updatedConstraints);
  }, [toggleNitrogen, toggleCoverProposal]);

  const handleNitrogenChange = (nitrogenToggle) => {
    if (existingNitrogenKey) {
      if (nitrogenToggle) {
        existingNitrogenKey.nitrogen = true;
      } else {
        existingNitrogenKey.nitrogen = false;
      }
    } else {
      if (nitrogenToggle) {
        updatedConstraints.push({
          nitrogen: true,
        });
      }
    }
    if (!nitrogenToggle) {
      existingNitrogenKey.nitrogen = false;
    }
    setSelectedConstraints(updatedConstraints);
  };

  const handleCoverProposalChange = (coverToggle) => {
    if (existingCoverProposalKey) {
      if (coverToggle) {
        existingCoverProposalKey.coverProposal = true;
      } else {
        existingCoverProposalKey.coverProposal = false;
      }
    } else {
      if (coverToggle) {
        updatedConstraints.push({
          coverProposal: true,
        });
      }
    }
    if (!coverToggle) {
      existingCoverProposalKey.coverProposal = false;
    }
    setSelectedConstraints(updatedConstraints);
  };

  /**
   * This function will be called when the user checks or unchecks the checkbox
   * for extra biomass.
   */
  const handleExtraBiomassChange = (extraBiomassToggle) => {
    const existingExtraBiomassKey = updatedConstraints.find((constraint) => 'extraBiomass' in constraint);
    if (existingExtraBiomassKey) {
      if (extraBiomassToggle) {
        existingExtraBiomassKey.extraBiomass = true;
      } else {
        existingExtraBiomassKey.extraBiomass = false;
      }
    } else {
      if (extraBiomassToggle) {
        updatedConstraints.push({
          extraBiomass: true,
        });
      }
    }

    if (!extraBiomassToggle) {
      existingExtraBiomassKey.extraBiomass = false;
    }
    setSelectedConstraints(updatedConstraints);
  };

  useEffect(() => {
    if (!exploit) {
      if (!exploit && localStorage.getItem('exploitation')) {
        setExploitation(+localStorage.getItem('exploitation'))
          .then((resp) => setExploit(resp.data.data))
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez une exploitation');
        navigate('/exploitation');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      loadData();
    }
  }, [exploit]);

  useEffect(() => {
    selectedParcels?.length > 0
      ? setSelectedParcels(selectedParcels)
      : setSelectedParcels(exploitationContext?.parcels);
  }, [exploitationContext?.parcels]);

  const loadData = () => {
    /* setParcels(exploitationContext?.parcels.filter((p) => !p.parent)); */
    getParcelsByExploitation(exploit.id).then((e) => {
      let parcelUpdated = [];
      exploitationContext.parcels.forEach((parcel) => {
        const parcelCoords = e.data.data.find(
          (parcelWithCoords) => parcelWithCoords.id === parcel.id
        );
        parcelUpdated.push({
          ...parcel,
          coordinates: parcelCoords.coordinates,
          cultureN: parcelCoords.cultureN,
          cultureN1: parcelCoords.cultureN1,
          groundType: parcelCoords.groundType,
        });
      });
      setExploitationContext({
        ...exploitationContext,
        parcels: parcelUpdated.filter((p) => !p.parent),
      });
      setCoordsExist(e.data.data.find((p) => p.coordinates?.length > 0));
    });

    //setExploitationContext({ ...exploitationContext, parcels: exploitationContext?.parcels.filter((p) => !p.parent) })
    getCulturesByExploitationId(exploit.id)
      .then((e) => {
        setCultures(
          e.data.data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((dt) => ({
              ...dt,
              selected: true,
            }))
        );
        selectedCultures.length > 0
          ? setSelectedCultures(selectedCultures)
          : setSelectedCultures(
              e.data.data.map((dt) => ({
                ...dt,
                selected: true,
              }))
            );
      })
      .catch((err) => {
        toast.error(err.data);
      });
    getConstraints(localStorage.getItem('cooperative'))
      .then((e) => setConstraints(e.data.data))
      .catch((err) => {
        toast.error(err.data);
      });
    getObjectives()
      .then((e) => {
        setObjectives(e.data.data);
        setSelectedObjectives(e.data.data.length === 1 ? e.data.data : []);
      })
      .catch((err) => {
        toast.error(err.data);
      });
  };

  useEffect(() => {
    if (limit.length > 0) return;
    const temp = cultures.map((culture) => ({
      id: culture.id,
      min: 0,
      max: 0,
      minPct: 0,
      maxPct: 0,
    }));
    setLimit(temp);
  }, [cultures]);

  useEffect(() => {
    if (exploit) {
      lastSimulate(exploit?.id)
        .then((e) => {
          const idCheckedParcels = e?.data?.data[0]?.simulation?.parcels;
          const selectedParcelsDefault = exploitationContext?.parcels.filter((b) => {
            return idCheckedParcels.includes(b.id) === true && !b.parent;
          });
          setSelectedParcels(selectedParcelsDefault);
          const idCheckedCultures = e?.data?.data[0]?.simulation?.cultures;
          const selectedCulturesDefault = cultures.map((b) => {
            if (idCheckedCultures.includes(b.id)) {
              return {
                ...b,
                selected: true,
              };
            } else {
              return {
                ...b,
                selected: false,
              };
            }
          });
          if (e?.data?.data[0]?.simulation?.obligationRestriction) {
            const obligationRestrictionVariable =
              e?.data?.data[0]?.simulation?.obligationRestriction;
            for (const index of obligationRestrictionVariable.keys()) {
              obligationRestrictionVariable[index].years = obligationRestrictionVariable[
                index
              ].years.filter((x) => {
                if (+x > currentYear && +x < currentYear + nbYears + 1) {
                  return x;
                }
              });

              // For plotDivision we change the type of value for the key constraint we need tu update old lastSimulate
              if (obligationRestrictionVariable[index].constraint === true) {
                obligationRestrictionVariable[index].constraint = 'obligation';
              } else if (obligationRestrictionVariable[index].constraint === false) {
                obligationRestrictionVariable[index].constraint = 'interdiction';
              }
              obligationRestrictionVariable[index].Parcel_Id = obligationRestrictionVariable[
                index
              ].Parcel_Id.filter(
                (parcelId) =>
                  !!selectedParcelsDefault.find((parcel) => {
                    if (parcel.id === parcelId) {
                      return parcel;
                    }
                  })
              );
            }

            setObligationAndRestriction(obligationRestrictionVariable);
          }
          setSelectedCultures(selectedCulturesDefault);

          const checkedConstraints = e?.data?.data[0]?.simulation?.constraints;
          const hasNitrogen = checkedConstraints.some((constraint) => 'nitrogen' in constraint);
          const hasCoverProposal = checkedConstraints.some(
            (constraint) => 'coverProposal' in constraint
          );
          const hasExtraBiomass = checkedConstraints.some((constraint) => 'extraBiomass' in constraint);
          const nitrogenConstraint = checkedConstraints.find(
            (constraint) => 'nitrogen' in constraint
          );
          const nitrogenConstraintTrue = checkedConstraints.find(
            (constraint) => 'nitrogen' in constraint && constraint.nitrogen === true
          );

          const coverProposalConstraintTrue = checkedConstraints.find(
            (constraint) => 'coverProposal' in constraint && constraint.coverProposal === true
          );

          const extraBiomassConstraintTrue = checkedConstraints.find(
            (constraint) => 'extraBiomass' in constraint && constraint.extraBiomass === true
          );  

          if (typeof nitrogenConstraint?.nitrogen === 'object') {
            const nitrogenIndex = checkedConstraints.indexOf(nitrogenConstraint);
            if (nitrogenConstraint?.nitrogen.length === 0) {
              if (!toggleCoverProposal && !hasCoverProposal) {
                checkedConstraints.push({
                  coverProposal: false,
                });
              }
              setToggleNitrogen(true);
              setToggleCoverProposal(false);
              setSelectedConstraints(checkedConstraints);
            }
            checkedConstraints[nitrogenIndex] = { nitrogen: true };
            if (Object.keys(nitrogenConstraint?.nitrogen?.coverProposal)) {
              setToggleCoverProposal(true);
              checkedConstraints.push({
                coverProposal: true,
              });
            }

            if (hasNitrogen) {
              setToggleNitrogen(true);
            }
            if (hasCoverProposal) {
              setToggleCoverProposal(true);
            }
          } else {
            if (nitrogenConstraintTrue) {
              setToggleNitrogen(true);
            } else {
              setToggleNitrogen(false);
            }

            if (coverProposalConstraintTrue) {
              setToggleCoverProposal(true);
            } else {
              setToggleCoverProposal(false);
            }
          }

          if (!hasNitrogen && !hasCoverProposal) {
            checkedConstraints.push({
              nitrogen: false,
            });
            checkedConstraints.push({
              coverProposal: false,
            });
            setSelectedConstraints(checkedConstraints);
          }

          if(hasExtraBiomass) {
            if(extraBiomassConstraintTrue) {
              setToggleExtraBiomass(true)
            }else {
              setToggleExtraBiomass(false)
            }
          } else {
            checkedConstraints.push({
              extraBiomass: false,
            });
          }

          setSelectedConstraints(checkedConstraints);

          const checkedObjectives = e?.data?.data[0]?.simulation?.objectives;
          setSelectedObjectives(checkedObjectives);
          const checkedYears = e?.data?.data[0]?.simulation?.yearsToSimulate;
          setNbYears(Number(checkedYears));
          const a = idCheckedCultures.map((b) => 0);
          const minChecked =
            e?.data?.data[0]?.simulation?.mins !== undefined
              ? e?.data?.data[0]?.simulation?.mins
              : a;
          const maxChecked =
            e?.data?.data[0]?.simulation?.maxs !== undefined
              ? e?.data?.data[0]?.simulation?.maxs
              : a;
          const minPctChecked =
            e?.data?.data[0]?.simulation?.minsPct !== undefined
              ? e?.data?.data[0]?.simulation?.minsPct
              : a;
          const maxPctChecked =
            e?.data?.data[0]?.simulation?.maxsPct !== undefined
              ? e?.data?.data[0]?.simulation?.maxsPct
              : a;

          if (limit.length > 0) return;
          const temp = cultures.map((culture) => {
            if (idCheckedCultures.includes(culture.id)) {
              const idCulture = idCheckedCultures.lastIndexOf(culture.id);
              return {
                id: culture.id,
                min: minChecked[idCulture],
                max: maxChecked[idCulture],
                minPct: minPctChecked[idCulture],
                maxPct: maxPctChecked[idCulture],
              };
            } else {
              return {
                id: culture.id,
                min: 0,
                max: 0,
                minPct: 0,
                maxPct: 0,
              };
            }
          });
          setLimit(temp);
        })
        .catch((err) => {});
    }
  }, [cultures]);
  //Détermination de la visibilité de ExploitationHeader en fonction de la position de isNavBarOpen
  useEffect(() => {
    const handleScroll = () => {
      const scrolledDown = window.scrollY > 65;
      setShowExploitationHeader(scrolledDown);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const toggleParcels = (value) => {
    selectedParcels.some((selectedParcel) => selectedParcel.id === value.id)
      ? setSelectedParcels(selectedParcels.filter((elem) => elem.id !== value.id))
      : setSelectedParcels([...selectedParcels, value]);

    checkObligationValuesCurrentlySelected(value.id, 'parcels');
  };

  const addObligationAndRestriction = () => {
    let current = [...obligationAndRestriction];
    current.push({
      Parcel_Id: [],
      Exploitation_Culture_Id: [],
      years: [],
      constraint: 'obligation',
    });
    setObligationAndRestriction(current);
    getYearsOptions();
  };

  const getYearsOptions = () => {
    const nextYearCampaign = currentYear + 1;
    let yearsOptions = [];
    for (let i = 0; i < nbYears; i++) {
      yearsOptions.push({
        id: nextYearCampaign + i,
        value: `${currentYear + i} - ${nextYearCampaign + i}`,
      });
    }
    return yearsOptions;
  };

  const updateObligationItem = (value, index, attr) => {
    let constraints = [...obligationAndRestriction];
    let checkIsOk = true;
    switch (attr) {
      case 'parcel':
        constraints[index].Parcel_Id = value;
        checkIsOk = checkObligationExist(constraints[index]);
        if (!checkIsOk) {
          constraints[index].Parcel_Id.splice(constraints[index].Parcel_Id.length - 1, 1);
        }
        break;
      case 'culture':
        constraints[index].Exploitation_Culture_Id = value;
        checkIsOk = checkObligationExist(constraints[index]);
        if (!checkIsOk) {
          constraints[index].Exploitation_Culture_Id.splice(
            constraints[index].Exploitation_Culture_Id.length - 1,
            1
          );
        }

        break;
      case 'year':
        if (value[value.length - 1] === 'all-years') {
          if (constraints[index].years.length === getYearsOptions().length) {
            constraints[index].years = [];
          } else {
            constraints[index].years = getYearsOptions().map((year) => year.id.toString());
          }
        } else {
          constraints[index].years = value;
        }
        checkIsOk = checkObligationExist(constraints[index]);
        if (!checkIsOk) {
          constraints[index].years.splice(constraints[index].years.length - 1, 1);
        }

        break;
      case 'constraint':
        constraints[index].constraint = value;
        break;
      default:
        break;
    }
    if (checkIsOk) {
      setObligationAndRestriction(constraints);
      setErrorObligation('');
    } else {
      setErrorObligation(
        'Une obligation/restriction existe déjà avec cette culture / année et parcelle.'
      );
    }
  };

  const ShowError = () => {
    let cleanedObligationRestriction = obligationAndRestriction.filter(
      (o) => o.Exploitation_Culture_Id.length > 0 || o.Parcel_Id.length > 0 || o.years.length > 0
    );

    let hasError =
      cleanedObligationRestriction.filter(
        (o) =>
          !o.Exploitation_Culture_Id ||
          o.Exploitation_Culture_Id.length === 0 ||
          !o.Parcel_Id ||
          o.Parcel_Id.length === 0 ||
          !o.years ||
          o.years.length === 0
      ).length > 0;

    if (hasError) {
      return (
        <p className="error">
          Des données sont manquantes dans les obligations et restrictions. Veuillez vérifier ces
          informations avant de lancer la simulation.
        </p>
      );
    } else {
      return <></>;
    }
  };

  const updateObligationsFromParcelModal = () => {
    if (parcelRestrictionValue !== null) {
      let addContraint = false;
      let indexToDelete = null;
      let constraints = [...obligationAndRestriction];
      const newLine = {
        Parcel_Id: [editData.parcelId],
        Exploitation_Culture_Id: [simulationModalCulture],
        years: [mapYear.toString()],
        constraint: parcelRestrictionValue,
      };

      if (!!obligationAndRestriction) {
        let restriction = parcelRestrictionValue;

        if (
          obligationAndRestriction.findIndex(
            (oAr) =>
              oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              oAr.years.some((year) => Number(year) === mapYear) &&
              oAr.constraint === restriction
          ) === -1
        ) {
          const checkAllConstraintNoEqual = obligationAndRestriction.findIndex(
            (oAr) =>
              oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              oAr.years.some((year) => Number(year) === mapYear) &&
              oAr.constraint !== restriction
          );

          const checkIfParcelToAdd = obligationAndRestriction.findIndex(
            (oAr) =>
              !oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              oAr.years.some((year) => Number(year) === mapYear) &&
              oAr.constraint === restriction
          );

          const checkIfCultureToAdd = obligationAndRestriction.findIndex(
            (oAr) =>
              oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              !oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              oAr.years.some((year) => Number(year) === mapYear) &&
              oAr.constraint === restriction
          );

          const checkIfYearAdd = obligationAndRestriction.findIndex(
            (oAr) =>
              oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              !oAr.years.some((year) => Number(year) === mapYear) &&
              oAr.constraint === restriction
          );

          if (
            (checkIfYearAdd === -1 &&
              checkIfCultureToAdd === -1 &&
              checkIfParcelToAdd === -1 &&
              checkAllConstraintNoEqual === -1) ||
            (checkIfYearAdd !== -1 &&
              (!!obligationAndRestriction[checkIfYearAdd].Parcel_Id ||
                !!obligationAndRestriction[checkIfYearAdd].Exploitation_Culture_Id)) ||
            (checkIfCultureToAdd !== -1 &&
              (!!obligationAndRestriction[checkIfCultureToAdd].Parcel_Id ||
                !!obligationAndRestriction[checkIfCultureToAdd].years)) ||
            (checkIfParcelToAdd !== -1 &&
              (!!obligationAndRestriction[checkIfParcelToAdd].Exploitation_Culture_Id ||
                !!obligationAndRestriction[checkIfParcelToAdd].years))
          ) {
            addContraint = true;
          }

          if (
            checkIfYearAdd !== -1 &&
            (!obligationAndRestriction[checkIfYearAdd].Parcel_Id ||
              !obligationAndRestriction[checkIfYearAdd].Exploitation_Culture_Id)
          ) {
            updateObligationItem(mapYear, checkIfYearAdd, 'year');
          }

          if (
            checkIfCultureToAdd !== -1 &&
            (!obligationAndRestriction[checkIfCultureToAdd].Parcel_Id ||
              !obligationAndRestriction[checkIfCultureToAdd].years)
          ) {
            updateObligationItem(simulationModalCulture, checkIfCultureToAdd, 'culture');
          }

          if (
            checkIfParcelToAdd !== -1 &&
            (!obligationAndRestriction[checkIfParcelToAdd].Exploitation_Culture_Id ||
              !obligationAndRestriction[checkIfParcelToAdd].years)
          ) {
            updateObligationItem(editData.parcelId, checkIfParcelToAdd, 'parcel');
          }

          if (
            checkAllConstraintNoEqual !== -1 &&
            (!obligationAndRestriction[checkAllConstraintNoEqual].Parcel_Id ||
              !obligationAndRestriction[checkAllConstraintNoEqual].Exploitation_Culture_Id ||
              !obligationAndRestriction[checkAllConstraintNoEqual].years)
          ) {
            updateObligationItem(restriction, checkAllConstraintNoEqual, 'constraint');
          }

          if (
            checkAllConstraintNoEqual !== -1 &&
            (!!obligationAndRestriction[checkAllConstraintNoEqual].Parcel_Id ||
              !!obligationAndRestriction[checkAllConstraintNoEqual].Exploitation_Culture_Id ||
              !!obligationAndRestriction[checkAllConstraintNoEqual].years)
          ) {
            if (
              obligationAndRestriction[checkAllConstraintNoEqual].Exploitation_Culture_Id.length ===
              1
            ) {
              indexToDelete = checkAllConstraintNoEqual;
            } else {
              const cultureIndex = obligationAndRestriction[
                checkAllConstraintNoEqual
              ].Exploitation_Culture_Id.findIndex((id) => id === simulationModalCulture);
              deleteValueObligation(checkAllConstraintNoEqual, cultureIndex, 'cultures');
            }
            if (restriction !== 'null') {
              addContraint = true;
            }
          }
        } else {
          const checkAllConstraint = obligationAndRestriction.findIndex(
            (oAr) =>
              oAr.Parcel_Id.some((parcel) => parcel === editData.parcelId) &&
              oAr.Exploitation_Culture_Id.some((culture) => culture === simulationModalCulture) &&
              oAr.years.some((year) => Number(year) === mapYear)
          );
          if (
            !!obligationAndRestriction[checkAllConstraint].Parcel_Id ||
            !!obligationAndRestriction[checkAllConstraint].Exploitation_Culture_Id ||
            !!obligationAndRestriction[checkAllConstraint].years
          ) {
            const parcelIndex = obligationAndRestriction[checkAllConstraint].Parcel_Id.findIndex(
              (parcel) => parcel === editData.parcelId
            );
            deleteValueObligation(checkAllConstraint, parcelIndex, 'parcels');
          } else {
            indexToDelete = checkAllConstraint;
          }
        }
      } else {
        addContraint = true;
      }

      if (indexToDelete >= 0 && addContraint === true) {
        constraints.splice(indexToDelete, 1);
        obligationAndRestriction
          ? setObligationAndRestriction([...constraints, newLine])
          : addObligationAndRestriction();
      }

      if (indexToDelete >= 0 && !addContraint) {
        constraints.splice(indexToDelete, 1);
        obligationAndRestriction
          ? setObligationAndRestriction(constraints)
          : addObligationAndRestriction();
      }

      if (addContraint === true && indexToDelete === null) {
        obligationAndRestriction
          ? setObligationAndRestriction([...obligationAndRestriction, newLine])
          : addObligationAndRestriction();
      }
    }
    setModal(false);
  };

  const updateParcel = (parcelId) => {
    const exploitationPracel = exploitationContext?.parcels.find(
      (parcel) => parcel.id === parcelId
    );
    let newArrI = [];
    let newArrO = [];
    let parcel_constraint = null;

    obligationAndRestriction.forEach((oAr) => {
      let currentParcel = oAr.Parcel_Id.some((parcel) => parcel === parcelId);
      if (currentParcel) {
        if (oAr.constraint === false || oAr.constraint === 'false') {
          oAr.Exploitation_Culture_Id.forEach((cultureId) => {
            const cultureObject = selectedCultures.find((culture) => culture.id === cultureId);
            oAr.years.forEach((oArYear) => {
              if (
                simulationModalCulture &&
                cultureId === simulationModalCulture &&
                Number(oArYear) === mapYear
              ) {
                parcel_constraint = false;
              }
              newArrI = [...newArrI, Number(oArYear) + ' - ' + cultureObject.name];
            });
          });
        }
        if (oAr.constraint === true || oAr.constraint === 'true') {
          oAr.Exploitation_Culture_Id.forEach((cultureId) => {
            const cultureObject = selectedCultures.find((culture) => culture.id === cultureId);
            oAr.years.forEach((oArYear) => {
              if (
                simulationModalCulture &&
                cultureId === simulationModalCulture &&
                Number(oArYear) === mapYear
              ) {
                parcel_constraint = true;
              }
              newArrO = [...newArrO, Number(oArYear) + ' - ' + cultureObject.name];
            });
          });
        }
      }
    });

    if (parcel_constraint === null) {
      setInputCheckedNeutre(true);
      setInputCheckedInterdiction(false);
      setInputCheckedObligation(false);
    } else {
      if (parcel_constraint.toString() === 'false') {
        setInputCheckedNeutre(false);
        setInputCheckedInterdiction(true);
        setInputCheckedObligation(false);
      }
      if (parcel_constraint.toString() === 'true') {
        setInputCheckedNeutre(false);
        setInputCheckedInterdiction(false);
        setInputCheckedObligation(true);
      }
    }

    setHasObligationOrRestriction(newArrI.length > 0 || newArrO.length > 0);
    setInterdictionList(newArrI);
    setObligationList(newArrO);
    setEditData({
      name: exploitationPracel.name,
      surface: exploitationPracel.surface,
      isIrrigable: exploitationPracel.isIrrigable,
      groundType: exploitationPracel.groundType || null,
      cultureN: exploitationPracel.cultureN || null,
      cultureN1: exploitationPracel.cultureN1 || null,
      id: exploitationPracel.id,
      parent: exploitationPracel.parent,
      isParent: exploitationPracel.isParent,
      constraint: parcel_constraint || null,
      parcelId: parcelId,
    });
    setModal(true);
  };

  const deleteInvalidateObligation = () => {
    let obligationValidate = obligationAndRestriction.filter(
      (o) =>
        o.Exploitation_Culture_Id &&
        o.Exploitation_Culture_Id.length > 0 &&
        o.Parcel_Id &&
        o.Parcel_Id.length > 0 &&
        o.years &&
        o.years.length > 0
    );
    setObligationAndRestriction(obligationValidate);
    return obligationValidate;
  };

  const checkObligationValuesCurrentlySelected = (value, attr) => {
    let obligations = [...obligationAndRestriction];
    switch (attr) {
      case 'parcels':
        obligations.forEach((o, index) => {
          if (o.Parcel_Id.indexOf(value) >= 0) {
            o.Parcel_Id.splice(o.Parcel_Id.indexOf(value), 1);
          }
        });
        break;
      case 'cultures':
        obligations.forEach((o, index) => {
          if (o.Exploitation_Culture_Id.indexOf(value) >= 0) {
            o.Exploitation_Culture_Id.splice(o.Exploitation_Culture_Id.indexOf(value), 1);
          }
        });
        break;
      case 'years':
        if (value !== '') {
          let year = currentYear;
          obligations.forEach((o, index) => {
            o.years = [...o.years.filter((y) => y <= year + (+value) )];
          });
        }
        break;
      default:
        break;
    }
    setObligationAndRestriction(obligations);
  };

  const deleteValueObligation = (index, indexAttr, attr) => {
    let obligations = [...obligationAndRestriction];
    switch (attr) {
      case 'years':
        obligations[index].years.splice(indexAttr, 1);
        break;
      case 'cultures':
        obligations[index].Exploitation_Culture_Id.splice(indexAttr, 1);
        break;
      case 'parcels':
        obligations[index].Parcel_Id.splice(indexAttr, 1);
        break;
      default:
        break;
    }
    setObligationAndRestriction(obligations);
  };

  const checkObligationExist = (value) => {
    let found = obligationAndRestriction.filter(
      (v) =>
        v.Parcel_Id.filter((p) => value.Parcel_Id.indexOf(p) >= 0).length > 0 &&
        v.Exploitation_Culture_Id.filter((p) => value.Exploitation_Culture_Id.indexOf(p) >= 0)
          .length > 0 &&
        v.years.filter((y) => value.years.indexOf(y) >= 0).length > 0 &&
        // Division is not antinomic with obligation/interdiction
        v.constraint !== 'division'
    );
    return found.length > 1 ? false : true;
  };

  const deleteObligation = (index) => {
    let constraints = [...obligationAndRestriction];
    constraints.splice(index, 1);
    setObligationAndRestriction(constraints);
  };

  const toggleCultures = (value, index) => {
    const tempSelectedCultures = [...selectedCultures];
    tempSelectedCultures[index] = {
      ...tempSelectedCultures[index],
      selected: !tempSelectedCultures[index].selected,
    };
    checkObligationValuesCurrentlySelected(value.id, 'cultures');
    setSelectedCultures(tempSelectedCultures);
  };

  const toggleObjectives = (value) => {
    if (setSelectedObjectives.length > 1) {
      selectedObjectives.indexOf(value) === -1
        ? setSelectedObjectives([...selectedObjectives, value])
        : setSelectedObjectives(selectedObjectives.filter((elem) => elem !== value));
    }
  };

  const toggleConstraints = (value) => {
    selectedConstraints.filter((item) => item.id === value.id).length === 0
      ? value.name.includes('TFI')
        ? setSelectedConstraints([...selectedConstraints, { ...value, max: 0 }])
        : setSelectedConstraints([...selectedConstraints, { ...value, min: 0, max: 0 }])
      : setSelectedConstraints(selectedConstraints.filter((elem) => elem.id !== value.id));
  };

  const checkContraints = () => {
    const activateCAPCategory = selectedConstraints?.find(
      (item) => item.name === 'activateCAPCategory'
    );
    const TFIConstraints = selectedConstraints?.filter(
      (item) => item?.id && item?.name.includes('TFI')
    );
    const CAPError =
      activateCAPCategory && (activateCAPCategory?.min === 0 || activateCAPCategory?.min === '');
    const TFIError = TFIConstraints.length && TFIConstraints.some((item) => item?.max > 1000);

    if (TFIError || CAPError) {
      if (CAPError) {
        toast.error(displayErrorMessage('ERR_CONSTRAINT_PAC'), {
          autoClose: false,
          toastId: 'pacError',
        });
      }

      if (TFIError) {
        toast.error(displayErrorMessage('ERR_CONSTRAINT_TFI'), {
          autoClose: false,
          toastId: 'tfiError',
        });
      }

      if (CAPError && !TFIError) {
        toast.dismiss('tfiError');
      }

      if (TFIError && !CAPError) {
        toast.dismiss('pacError');
      }

      return false;
    }

    toast.dismiss();
    setCurrentStep(3);
    return true;
  };

  const checkAndSetStep = (i) => {
    if (currentStep === i) {
      return;
    }
    if (!validateStepOrSubmitSimu()) {
      return;
    }
    if (checkContraints()) {
      setCurrentStep(i);
    }
  };

  const onSubmitYears = (yearNumber) => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(yearNumber, errorsFormDefault) });
    if (!errorsFormDefault.formError) {
      setCurrentStep(4);
    }
  };

  const constraintWithChildren = (parent, index) => {
    const thisConstraint = (constraint) =>
      selectedConstraints.filter((item) => item.id === constraint.id);

    return (
      <Fragment key={parent.name + index}>
        <p className="parent">
          {parent.displayName === 'Besoins animaux' ? 'Co-produits' : parent.displayName}
        </p>
        {parent.children.map((c, index) => (
          <Fragment key={c.name + index}>
            <Checkbox
              key={v1()}
              checked={selectedConstraints.filter((constraint) => c.id === constraint.id).length}
              onChange={() => toggleConstraints(c)}
              value={c}
              label={`${c.displayName}`}
              containerClass="children"
            />
            {thisConstraint(c).length === 1 && (
              <div className="minMax">
                {!thisConstraint(c)[0]?.name.includes('TFI') && (
                  <>
                    <p>Min :</p>
                    <Input
                      name={`${c.id}_min`}
                      onChange={(v) => {
                        const deepCopy = structuredClone(selectedConstraints);
                        deepCopy.filter((i) => i.id === c.id)[0].min = +v;
                        setSelectedConstraints(deepCopy);
                      }}
                      value={selectedConstraints.filter((i) => i.id === c.id)[0].min}
                    />
                  </>
                )}
                <p>Max :</p>
                <Input
                  name={`${c.id}_max`}
                  onChange={(v) => {
                    v = emptyFieldToZero(v);
                    const deepCopy = structuredClone(selectedConstraints);
                    deepCopy.filter((i) => i.id === c.id)[0].max = v;
                    setSelectedConstraints(deepCopy);
                  }}
                  value={selectedConstraints.filter((i) => i.id === c.id)[0].max}
                  dataType={thisConstraint(c)[0]?.name.includes('TFI') ? 'number' : 'digit'}
                />
              </div>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  const dataMissingObligation = (data) => {
    return data && data !== '' ? data : <span className="missing_data">Données Manquantes</span>;
  };

  const categoryPACsWithChildren = (parent, index) => (
    <Fragment key={parent.name + index}>
      <p className="parent">{parent.displayName}</p>
      {parent.children.map((c, index) => (
        <Fragment key={c.name + index}>
          <Checkbox
            key={v1()}
            checked={selectedConstraints.filter((constraint) => c.id === constraint.id).length}
            onChange={() => toggleConstraints(c)}
            value={c}
            label={`${c.displayName}`}
            containerClass="children"
          />
          {selectedConstraints.filter((item) => item.id === c.id).length === 1 && (
            <div className="missingPoints">
              <p>Nombre de point(s) à aller chercher :</p>
              <Input
                type="number"
                pattern="^[1-5]{1}$"
                name={`${c.id}_points`}
                onChange={(v) => {
                  if (v > 0 && v < 6) {
                  } else {
                    v = '';
                  }
                  const deepCopy = JSON.parse(JSON.stringify(selectedConstraints));
                  deepCopy.filter((i) => i.id === c.id)[0].min = v;
                  setSelectedConstraints(deepCopy);
                }}
                value={selectedConstraints.filter((i) => i.id === c.id)[0].min}
              />
            </div>
          )}
        </Fragment>
      ))}
    </Fragment>
  );

  useEffect(() => {
    setMaxs([
      ...limit
        .filter((li) => {
          const idArr = selectedCultures.filter((item) => item.selected).map((se) => se.id);
          return idArr.includes(li.id);
        })
        .map((li) => Number(li.max)),
    ]);
    setMins([
      ...limit
        .filter((li) => {
          const idArr = selectedCultures.filter((item) => item.selected).map((se) => se.id);
          return idArr.includes(li.id);
        })
        .map((li) => Number(li.min)),
    ]);
    setMinsPct([
      ...limit
        .filter((li) => {
          const idArr = selectedCultures.filter((item) => item.selected).map((se) => se.id);
          return idArr.includes(li.id);
        })
        .map((li) => Number(li.minPct)),
    ]);
    setMaxsPct([
      ...limit
        .filter((li) => {
          const idArr = selectedCultures.filter((item) => item.selected).map((se) => se.id);
          return idArr.includes(li.id);
        })
        .map((li) => Number(li.maxPct)),
    ]);
  }, [limit, selectedCultures]);

  function setParcelRestriction(event) {
    const newValue = event.target.value;
    if (newValue.toString() === 'null') {
      setInputCheckedNeutre(true);
      setInputCheckedInterdiction(false);
      setInputCheckedObligation(false);
    }
    if (newValue.toString() === 'false') {
      setInputCheckedNeutre(false);
      setInputCheckedInterdiction(true);
      setInputCheckedObligation(false);
    }
    if (newValue.toString() === 'true') {
      setInputCheckedNeutre(false);
      setInputCheckedInterdiction(false);
      setInputCheckedObligation(true);
    }
    setparcelRestrictionValue(newValue);
  }

  const cleanObligationRestriction = () => {
    let cleanedObligationRestriction = obligationAndRestriction.filter(
      (obligation) =>
        obligation.Exploitation_Culture_Id.length ||
        obligation.Parcel_Id.length ||
        obligation.years.length
    );
    setObligationAndRestriction(cleanedObligationRestriction);
  };

  const showStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="step">
            <SimulationCard title="Sélection des parcelles">
              <>
                {exploitationContext?.parcels.map((e) => {
                  return (
                    <div key={v1()}>
                      <Checkbox
                        key={v1()}
                        checked={selectedParcels?.filter((parcel) => e.id === parcel.id).length}
                        onChange={(value) => toggleParcels(value)}
                        value={e}
                        label={`${e.name} (${e.surface} ha) ${
                          e.isParent
                            ? `(Surface du regroupement : ${getTotalSurfaceParcelGroup(
                                e.surface,
                                exploit?.parcels.filter((p) => p?.parent === e?.id)
                              )} ha)`
                            : ''
                        }`}
                      />
                    </div>
                  );
                })}
                <hr />
                <Checkbox
                  checked={selectedParcels?.length === exploitationContext?.parcels.length}
                  onChange={() => {
                    selectedParcels.length === exploitationContext?.parcels.length
                      ? setSelectedParcels([])
                      : setSelectedParcels(exploitationContext?.parcels);
                  }}
                  value="*"
                  label="Tout sélectionner"
                />
              </>
            </SimulationCard>
            <Button
              type="submit"
              text="Valider"
              onClick={() => setCurrentStep(1)}
              disabled={!(selectedParcels?.length > 0)}
            />
          </div>
        );
      case 1:
        return (
          <div className="step">
            <SimulationCard title="Sélection des cultures">
              <>
                {selectedCultures.map((e, index) => (
                  <div className="listItem" key={e.name + index}>
                    <Checkbox
                      key={v1()}
                      checked={e.selected}
                      onChange={(e) => toggleCultures(e, index)}
                      value={e}
                      label={e.name}
                      labelClass="parent"
                    />

                    {e.selected && (
                      <MinMaxCultures key={e.id} id={e.id} limit={limit} setLimit={setLimit} />
                    )}
                  </div>
                ))}
                <hr />
                <Checkbox
                  checked={
                    selectedCultures.filter((item) => item.selected).length ===
                    selectedCultures.length
                  }
                  onChange={(e) => {
                    if (
                      selectedCultures.filter((item) => item.selected).length ===
                      selectedCultures.length
                    ) {
                      setSelectedCultures(
                        selectedCultures.map((item) => ({
                          ...item,
                          selected: false,
                        }))
                      );
                    } else {
                      setSelectedCultures(
                        selectedCultures.map((item) => ({
                          ...item,
                          selected: true,
                        }))
                      );
                    }
                  }}
                  value="*"
                  label="Tout sélectionner"
                />{' '}
              </>
            </SimulationCard>
            <Button
              type="submit"
              text="Valider"
              onClick={() => {
                setCurrentStep(2);
              }}
              disabled={!(selectedCultures.filter((item) => item.selected).length > 0)}
            />
          </div>
        );
      case 2:
        return (
          <div className="step">
            <SimulationCard title="Sélection des contraintes">
              <>
                {constraints.map((e, index) =>
                  e.children ? (
                    e.children.length ? (
                      e.name === 'capCategory' ? (
                        categoryPACsWithChildren(e, index)
                      ) : (
                        constraintWithChildren(e, index)
                      )
                    ) : (
                      <Fragment key={e.name + index}>
                        <Checkbox
                          key={v1()}
                          checked={
                            selectedConstraints.filter((constraint) => e.id === constraint.id)
                              .length
                          }
                          onChange={() => toggleConstraints(e)}
                          value={e}
                          label={e.displayName}
                          labelClass="parent"
                        />
                        {selectedConstraints.filter((item) => item.id === e.id).length === 1 && (
                          <span className="minMax">
                            Min:
                            <Input
                              name={`${e.id}_min`}
                              onChange={(v) => {
                                const deepCopy = JSON.parse(JSON.stringify(selectedConstraints));
                                deepCopy.filter((i) => i.id === e.id)[0].min = +v;
                                setSelectedConstraints(deepCopy);
                              }}
                              value={selectedConstraints.filter((i) => i.id === e.id)[0].min}
                            />
                            Max:
                            <Input
                              name={`${e.id}_max`}
                              onChange={(v) => {
                                const deepCopy = JSON.parse(JSON.stringify(selectedConstraints));
                                deepCopy.filter((i) => i.id === e.id)[0].max = +v;
                                setSelectedConstraints(deepCopy);
                              }}
                              value={selectedConstraints.filter((i) => i.id === e.id)[0].max}
                            />
                          </span>
                        )}
                      </Fragment>
                    )
                  ) : (
                    ''
                  )
                )}
                <>
                  <p className="parent">Azote</p>
                  <>
                    <Checkbox
                      key={v1()}
                      checked={toggleNitrogen}
                      onChange={(e) => {
                        setToggleNitrogen(!toggleNitrogen);
                        handleNitrogenChange(!toggleNitrogen);
                      }}
                      value={'nitrogen'}
                      label={`Calcul de l'Azote`}
                      containerClass="children"
                    />
                  </>
                </>
                <>
                  <p className="parent">Proposition de couverts</p>
                  <>
                    <Checkbox
                      key={v1()}
                      checked={toggleCoverProposal}
                      onChange={(e) => {
                        setToggleCoverProposal(!toggleCoverProposal);
                        handleCoverProposalChange(!toggleCoverProposal);
                      }}
                      value={'coverProposal'}
                      label={`Proposition de couverts`}
                      containerClass="children"
                    />
                  </>
                  <>
                    <p className="parent">Affichage matières sèches</p>
                    <>
                      <Checkbox
                        key={v1()}
                        checked={toggleExtraBiomass}
                        onChange={(e) => {
                          setToggleExtraBiomass(!toggleExtraBiomass);
                          handleExtraBiomassChange(!toggleExtraBiomass);
                        }}
                        value={'extraBiomass'}
                        label={`Matières sèches`}
                        containerClass="children"
                      />
                    </>
                  </>
                </>
              </>
            </SimulationCard>
            <Button type="submit" text="Valider" onClick={() => checkContraints()} />
          </div>
        );
      case 3:
        return (
          <div className="step">
            <SimulationCard title="Sélection des objectifs">
              <>
                {objectives.map((e) => (
                  <Checkbox
                    key={v1()}
                    checked={selectedObjectives.filter((objective) => e.id === objective.id).length}
                    onChange={toggleObjectives}
                    value={e}
                    label={e.displayName}
                  />
                ))}
                <hr />
                {/* <p className="requirements">Maximum 3 objectifs sélectionnables</p>*/}
              </>
            </SimulationCard>
            <SimulationCard title={`Nombre d'années à simuler`}>
              <>
                {errorsFormDefault?.yearNumber.message && (
                  <p className="errorForm">{errorsFormDefault?.yearNumber?.message}</p>
                )}
                <div className="input_field">
                  <InputLabelOld
                    name="yearNumber"
                    value={nbYears}
                    onChange={(e) =>
                      setNbYears(e) & checkObligationValuesCurrentlySelected(e, 'years')
                    }
                    defaultStyle="primary"
                    placeholder=""
                    label="nombre d'années : "
                  />
                </div>

                <hr />
                <p className="requirements">Minimum : 3 années / Maximum : 7 années</p>
              </>
            </SimulationCard>
            <Button
              type="submit"
              text="Valider"
              onClick={() => onSubmitYears({ yearNumber: nbYears })}
              disabled={!(selectedCultures.filter((item) => item.selected).length > 1)}
            />
          </div>
        );
      case 4:
        return (
          <div className="step">
            <SimulationCard title="Contraintes parcellaires">
              <div>
                <div className="map_button_container">
                  {!!exploitationContext?.parcels.find((p) => p.coordinates?.length > 0) && (
                    <>
                      <Button
                        defaultStyle="group_button"
                        text="Liste"
                        onClick={() => {
                          showMap(false);
                        }}
                      />
                      <Button
                        defaultStyle="group_button"
                        text="Carte"
                        onClick={() => {
                          showMap(true);
                        }}
                      />
                    </>
                  )}
                </div>

                {!!coordsExist && (
                  <div style={{ display: mapTab ? 'block' : 'none' }}>
                    <div className="map_section">
                      <MapConstraints
                        simulationYears={getYearsOptions()}
                        updateParcel={updateParcel}
                        culturesList={selectedCultures.filter((item) => item.selected)}
                        simulationModalCulture={simulationModalCulture}
                        setSimulationModalCulture={setSimulationModalCulture}
                        mapYear={mapYear}
                        setMapYear={setMapYear}
                        center={mapTab}
                      ></MapConstraints>
                    </div>
                  </div>
                )}

                <div style={{ display: mapTab ? 'none' : 'block' }}>
                  <div>
                    <ThemeProvider theme={MultiSelectTheme}>
                      <>
                        <div className="error_message">
                          {errorObligation !== '' ? (
                            <span className="error_obligation">{errorObligation}</span>
                          ) : (
                            <></>
                          )}
                        </div>
                        {obligationAndRestriction.length > 0 ? (
                          <div className="obligation_and_restriction">
                            {obligationAndRestriction.map((v, index) => (
                              <div
                                className="row_obligation"
                                key={'obligation-restriction-' + index}
                              >
                                <div className="bloc_obligation">
                                  <label htmlFor="select_parcel" className="label_bloc_obligation">
                                    Parcelle
                                  </label>
                                  <FormControl>
                                    <InputLabel shrink={false} id="select_parcel_label">
                                      Sélectionner
                                    </InputLabel>
                                    <SelectMui
                                      sx={{ height: 50, textAlign: 'left' }}
                                      labelId="select_parcel_label"
                                      id="select_parcel"
                                      name="select_parcel"
                                      multiple
                                      value={obligationAndRestriction[index].Parcel_Id}
                                      onChange={(event) =>
                                        updateObligationItem(event.target.value, index, 'parcel')
                                      }
                                      renderValue={() => ''}
                                      MenuProps={{ autoFocus: false }}
                                    >
                                      {selectedParcels.length > 0 &&
                                        [...selectedParcels].map((child) => (
                                          <MenuItem key={child.id} value={child.id}>
                                            <CheckboxMui
                                              checked={
                                                obligationAndRestriction[index].Parcel_Id.indexOf(
                                                  child.id
                                                ) > -1
                                              }
                                            />
                                            <ListItemText primary={child.name} />
                                          </MenuItem>
                                        ))}
                                    </SelectMui>
                                  </FormControl>
                                  <div className="multi_badge">
                                    {v.Parcel_Id.map((p, indexParcel) => (
                                      <span key={indexParcel} className="badge">
                                        {selectedParcels.find((par) => par.id === +p)?.name}{' '}
                                        <span
                                          className="delete"
                                          onClick={() =>
                                            deleteValueObligation(index, indexParcel, 'parcels')
                                          }
                                        >
                                          <CancelIcon />
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                <div className="bloc_obligation">
                                  <label htmlFor="select_culture" className="label_bloc_obligation">
                                    Culture
                                  </label>
                                  <FormControl>
                                    <InputLabel shrink={false} id="select_culture_label">
                                      Sélectionner
                                    </InputLabel>
                                    <SelectMui
                                      sx={{ height: 50 }}
                                      labelId="select_culture_label"
                                      id="select_culture"
                                      name="select_culture"
                                      multiple
                                      value={
                                        obligationAndRestriction[index].Exploitation_Culture_Id
                                      }
                                      onChange={(event) =>
                                        updateObligationItem(event.target.value, index, 'culture')
                                      }
                                      input={<OutlinedInput label="Tag" />}
                                      renderValue={() => ''}
                                      MenuProps={{ autoFocus: false }}
                                    >
                                      {selectedCultures.length > 0 &&
                                        [...selectedCultures.filter((item) => item.selected)].map(
                                          (child) => (
                                            <MenuItem key={child.id} value={child.id}>
                                              <CheckboxMui
                                                checked={
                                                  obligationAndRestriction[
                                                    index
                                                  ].Exploitation_Culture_Id.indexOf(child.id) > -1
                                                }
                                              />
                                              <ListItemText primary={child.name} />
                                            </MenuItem>
                                          )
                                        )}
                                    </SelectMui>
                                  </FormControl>
                                  <div className="multi_badge">
                                    {v.Exploitation_Culture_Id.map((c, indexCulture) => (
                                      <span key={indexCulture} className="badge">
                                        {selectedCultures.find((cul) => cul.id === +c)?.name}{' '}
                                        <span
                                          className="delete"
                                          onClick={() =>
                                            deleteValueObligation(index, indexCulture, 'cultures')
                                          }
                                        >
                                          <CancelIcon />
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                <div className="bloc_obligation">
                                  <label htmlFor="select_years" className="label_bloc_obligation">
                                    Campagne
                                  </label>
                                  <FormControl>
                                    <InputLabel shrink={false} id="select_campagne_label">
                                      Sélectionner
                                    </InputLabel>
                                    <SelectMui
                                      sx={{ height: 50 }}
                                      labelId="select_campagne_label"
                                      id="select_campagne"
                                      name="select_years"
                                      multiple
                                      value={obligationAndRestriction[index].years}
                                      onChange={(event) =>
                                        updateObligationItem(event.target.value, index, 'year')
                                      }
                                      input={<OutlinedInput label="Tag" />}
                                      renderValue={() => ''}
                                      MenuProps={{ autoFocus: false }}
                                    >
                                      <MenuItem key={'select-all-years'} value={'all-years'}>
                                        <CheckboxMui
                                          checked={
                                            obligationAndRestriction[index].years.length ===
                                            getYearsOptions().length
                                          }
                                        />
                                        <ListItemText primary={'Tout sélectionner'} />
                                      </MenuItem>
                                      {[getYearsOptions()].length > 0 &&
                                        [...getYearsOptions()].map((child) => (
                                          <MenuItem key={child.value} value={child.id.toString()}>
                                            <CheckboxMui
                                              checked={
                                                obligationAndRestriction[index].years.indexOf(
                                                  child.id.toString()
                                                ) > -1
                                              }
                                            />
                                            <ListItemText primary={child.value} />
                                          </MenuItem>
                                        ))}
                                    </SelectMui>
                                  </FormControl>
                                  <div className="multi_badge">
                                    {v.years.map((y, indexYear) => (
                                      <span key={indexYear} className="badge badge_years">
                                        {y - 1 + '-' + parseInt(y)}
                                        <span
                                          className="delete"
                                          onClick={() =>
                                            deleteValueObligation(index, indexYear, 'years')
                                          }
                                        >
                                          <CancelIcon />
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                <div className="bloc_obligation">
                                  <label
                                    htmlFor="select_obligation"
                                    className="label_bloc_obligation"
                                  >
                                    Etat
                                  </label>
                                  <FormControl>
                                    <SelectMui
                                      sx={{ height: 50 }}
                                      labelId="select_obligation_label"
                                      id="select_obligation"
                                      name="select_obligation"
                                      value={obligationAndRestriction[index].constraint}
                                      onChange={(event) =>
                                        updateObligationItem(
                                          event.target.value,
                                          index,
                                          'constraint'
                                        )
                                      }
                                      input={<OutlinedInput label="Tag" />}
                                    >
                                      {[
                                        { name: 'Obligatoire', id: 'obligation' },
                                        { name: 'Interdit', id: 'interdiction' },
                                        { name: 'Découpable', id: 'division' },
                                      ].map((child) => (
                                        <MenuItem key={child.name} value={child.id}>
                                          {child.name}
                                        </MenuItem>
                                      ))}
                                    </SelectMui>
                                  </FormControl>
                                </div>
                                <div className="bloc_obligation">
                                  <span
                                    className="delete_obligation"
                                    onClick={() => deleteObligation(index)}
                                  >
                                    <DeleteIcon sx={{ color: '#eb4d4b', scale: '1.5' }} />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                        <Button
                          type="button"
                          text="AJOUTER +"
                          onClick={() => addObligationAndRestriction()}
                          defaultStyle="add_restriction_button"
                        ></Button>
                      </>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </SimulationCard>

            {modal && (
              <FormModal
                onClick={() => {
                  setEditData(null);
                  setModal(false);
                }}
                customTemplate={
                  <div className="update_modal">
                    <h1>{editData?.name}</h1>
                    <div className="parcel_infos">
                      <p>
                        <span>Surface :</span>
                        <span>{editData?.surface}</span>
                      </p>
                      <p>
                        <span>Culture :</span>
                        <span> {editData?.cultureN?.name}</span>
                      </p>
                      <p>
                        <span>Culture N-1 :</span>
                        <span>{editData?.cultureN1?.name}</span>
                      </p>
                      <p>
                        <span>Type de sol : </span>
                        <span> {editData?.groundType?.name}</span>
                      </p>
                      <p>
                        <span>Irrigabilité :</span>
                        <span>{editData?.isIrrigable ? 'Oui' : 'Non'}</span>
                      </p>
                    </div>

                    {hasObligationOrRestriction && (
                      <div className="obligation_restriction_details">
                        <div className="interdiction_obligation">
                          {interdictionList && (
                            <div className="interdiction">
                              <p>
                                <span
                                  className="interdiction_color color"
                                  style={{ background: 'red' }}
                                ></span>
                                Interdiction
                              </p>
                              {
                                <ul className="list-content" style={{ textAlign: 'left' }}>
                                  {interdictionList?.map((o) => (
                                    <li key={v1()} className="list-item">
                                      {o}
                                    </li>
                                  ))}
                                </ul>
                              }
                            </div>
                          )}

                          {obligationList && (
                            <div className="obligation">
                              <p>
                                <span
                                  className="obligation_color color"
                                  style={{ background: 'green' }}
                                ></span>
                                Obligation
                              </p>
                              <ul className="list-content" style={{ textAlign: 'left' }}>
                                {obligationList?.map((o) => (
                                  <li key={v1()} className="list-item">
                                    {o}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <h2>
                      {mapYear}{' '}
                      {!!simulationModalCulture && (
                        <span>
                          {' '}
                          -
                          {
                            selectedCultures.find(
                              (culture) => culture.id === simulationModalCulture
                            )?.name
                          }
                        </span>
                      )}
                    </h2>

                    {!!simulationModalCulture && (
                      <div className="restrictions_input_container">
                        <ul className="restrictions_input">
                          <li key={v1()}>
                            <input
                              type="radio"
                              name="restrictions"
                              value={'interdiction'}
                              id="input_interdiction"
                              onChange={setParcelRestriction}
                              defaultChecked={inputCheckedInterdiction}
                            />
                            <label
                              htmlFor="input_interdiction"
                              className={inputCheckedInterdiction ? 'labelInterdiction' : ''}
                            >
                              Interdiction
                            </label>
                          </li>
                          <li key={v1()}>
                            <input
                              type="radio"
                              name="restrictions"
                              value={'obligation'}
                              id="input_obligation"
                              onChange={setParcelRestriction}
                              defaultChecked={inputCheckedObligation}
                            />
                            <label
                              htmlFor="input_obligation"
                              className={inputCheckedObligation ? 'labelObligation' : ''}
                            >
                              Obligation
                            </label>
                          </li>
                          <li key={v1()}>
                            <input
                              type="radio"
                              name="restrictions"
                              value="null"
                              id="input_neutre"
                              onChange={setParcelRestriction}
                              defaultChecked={inputCheckedNeutre}
                            />
                            <label
                              htmlFor="input_neutre"
                              className={inputCheckedNeutre ? 'labelNeutre' : ''}
                            >
                              Neutre
                            </label>
                          </li>
                        </ul>
                      </div>
                    )}
                    {simulationModalCulture && (
                      <div className="button_container">
                        {
                          <Button
                            defaultStyle="group_button"
                            text="Valider"
                            onClick={() => updateObligationsFromParcelModal()}
                          />
                        }
                      </div>
                    )}
                  </div>
                }
              ></FormModal>
            )}
            <Button
              type="submit"
              text="Valider"
              onClick={() => {
                cleanObligationRestriction(), setCurrentStep(5);
              }}
              disabled={!(selectedCultures.filter((item) => item.selected).length > 1)}
            />
          </div>
        );
      case 5:
        return (
          <>
            <div className="step">
              <div className="recap">
                <div className="column borderRight">
                  <h2>Parcelles</h2>
                  {selectedParcels.map((p) => (
                    <p key={v1()}> - {p.name}</p>
                  ))}
                </div>
                <div className="column borderRight">
                  <h2>Cultures</h2>
                  {selectedCultures
                    .filter((item) => item.selected)
                    .map((e, index) => (
                      <p key={v1()}>
                        {' '}
                        - {e.name} <br />
                        {(mins[index] > 0 || maxs[index] > 0) &&
                          `min: ${mins[index]}ha max: ${maxs[index]}ha`}
                        {(minsPct[index] > 0 || maxsPct[index]) > 0 &&
                          `min: ${minsPct[index]}% max: ${maxsPct[index]}%`}
                      </p>
                    ))}
                </div>
                <div className="column borderRight">
                  <h2>Contraintes</h2>
                  {selectedConstraints.map((constraint) => (
                    <>
                      {!('nitrogen' in constraint) 
                      && !('coverProposal' in constraint) 
                      && !('extraBiomass' in constraint) 
                      && (
                        <p key={v1()}>
                          - {constraint.displayName} <br />{' '}
                          {(constraint.min || constraint.min == 0) && `min: ${constraint.min} `}
                          {(constraint.max || constraint.max == 0) && `max: ${constraint.max}`}
                        </p>
                      )}
                      {constraint.nitrogen && <p> - Calcul de l'azote</p>}
                      {constraint.coverProposal && <p> - Proposition de couverts</p>}
                      {constraint.extraBiomass && <p> - Affichage matières sèches</p>}
                    </>
                  ))}
                </div>
                <div className="column">
                  <h2>Objectifs</h2>
                  {selectedObjectives.map((p) => (
                    <p key={v1()}> - {p.displayName}</p>
                  ))}
                  <p>Nombre d'années à simuler : {nbYears}</p>
                </div>
                <div className="column">
                  <h2>Contraintes parcellaires</h2>

                  {obligationAndRestriction.map((obligation) => (
                    <p key={v1()}>
                      -{' '}
                      {dataMissingObligation(
                        obligation?.Parcel_Id?.map(
                          (parcel) =>
                            selectedParcels?.find((selectedParcel) => selectedParcel.id === parcel)
                              ?.name
                        )
                          ?.filter((parcelName) => parcelName)
                          ?.join(' - ')
                      )}
                      |{' '}
                      {dataMissingObligation(
                        obligation.Exploitation_Culture_Id.map(
                          (parcel) =>
                            selectedCultures?.find((selectedParcel) => selectedParcel.id === parcel)
                              ?.name
                        )?.join(' - ')
                      )}
                      | {dataMissingObligation(obligation.years.join(' - '))} |{' '}
                      {obligation.constraint === 'division'
                        ? 'Découpable'
                        : obligation.constraint === 'obligation'
                        ? 'Obligatoire'
                        : 'Restreinte'}
                    </p>
                  ))}
                </div>
              </div>
              <Button
                type="submit"
                text="Simulation"
                onClick={() => {
                  handleSimulationSubmit();
                }}
              />
            </div>
          </>
        );
      default:
        return <p>unknow</p>;
    }
  };

  const handleSimulationSubmit = () => {
    if (!validateStepOrSubmitSimu()) {
      return;
    }
    const tempSelectedCultures = selectedCultures
      .filter((item) => item.selected)
      .map((item) => {
        const { selected, ...newitem } = item;
        return newitem;
      });

    selectedConstraints.forEach((constraint) => {
      if (constraint?.coProductName && constraint?.coProductDisplayName) {
        constraint.coProductFlag = true;
      }
      if (constraint?.name?.includes('TFI')) {
        constraint.max = parseFloat(constraint.max);
      }
    });

    let obligationValidate = deleteInvalidateObligation();
    navigate('/exploitation/result', {
      state: {
        selectedParcels,
        selectedConstraints,
        selectedCultures: tempSelectedCultures,
        selectedObjectives,
        exploitationSelected: exploit,
        nbYears,
        mins,
        maxs,
        minsPct,
        maxsPct,
        obligationAndRestriction: obligationValidate,
      },
    });
  };

  return (
    <div className="Content_simulation">
      <div className="section simulation">
        <div
          className={
            currentStep === 5
              ? 'title_section_recapitulatif title_spacing'
              : 'title_section title_spacing'
          }
          style={{ top: isNavBarOpen ? '125px' : '75px' }}
        >
          Simulation
          {currentStep === 5 && (
            <>
              <div className="exploitationHeaderSticky">
                {showExploitationHeader && <ExploitationHeader />}
              </div>

              <ButtonMui
                className="button-top-simulate"
                type="submit"
                onClick={() => {
                  handleSimulationSubmit();
                }}
              >
                Simuler
              </ButtonMui>
            </>
          )}
        </div>
        {currentStep === 5 ? <ShowError></ShowError> : <></>}
        <div className="content">
          <SimulationSteps
            steps={simulationSteps}
            currentStep={currentStep}
            changeStep={checkAndSetStep}
            cleanObligationRestriction={cleanObligationRestriction}
            checkContraints={checkContraints}
          />
          {showStep()}
        </div>
      </div>
    </div>
  );
};

SimulationExploitations.propTypes = {
  exploitation: function (props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (PropTypes.shape(exploitationProp)) return;
    return new Error(`${componentName} only accepts null or exploitationProp`);
  },
  setExploitation: PropTypes.any,
};

export default SimulationExploitations;
